import { fetchPost, fetchGet } from '../index';

// 查询Program table数据接口
export const getSalesCompetitionList = (
    params = {
        bu: '',
        programPeriod: '',
        dealerCode: '',
        dealerName: '',
        dealerGroup: '',
    }
): Promise<any> => {
    return fetchPost(
        // `/claimapi/salesCompetitionResult/search?bu=${params.bu}&programPeriod=${params.programPeriod}&dealerCode=${params.dealerCode}&dealerName=${params.dealerName}&dealerGroup=${params.dealerGroup}`,
        `/claimapi/salesCompetitionResult/search`,
        params
    );
};
//获取DealerGroup
export const getDealerGroup = (): Promise<any> => {
    return fetchGet('/dapi/noAuth/dealer/dealerGroupList');
};
