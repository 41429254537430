
//http://localhost:8080/#/claim/EnquiryProgram
import { defineComponent, ref, reactive, nextTick } from 'vue';
import {
    getSalesCompetitionList,
    getDealerGroup,
} from '@/API/salesReporting/salesCompetationResult';
import { message, TreeSelect } from 'ant-design-vue';
import downloadFile from '@/utils/claim/downloadFile';
import moment from 'moment';
import { useStore } from 'vuex';
import DM from '@/views/SalesReporting/components/downloadModal.vue';
const SHOW_PARENT = TreeSelect.SHOW_PARENT;
const tableWidth = window.innerWidth;
const summaryCol = reactive([
    { title: 'Program Code', dataIndex: 'programCode', width: 200 },
    { title: 'Program Name', dataIndex: 'programName', width: 400 },
    { title: 'Program Period', dataIndex: 'programPeriod', width: 200 },
    { title: 'Dealer Code (Cofico)', dataIndex: 'dealerCode', width: 200 },
    { title: 'Dealer Code (SWT)', dataIndex: 'dealerSwtCode', width: 200 },
    {
        title: 'Dealer Name',
        dataIndex: 'dealerNameEn',
        width: 400,
    },
    { title: 'Dealer Group', dataIndex: 'dealerGroupEn', width: 200 },
    { title: 'Region', dataIndex: 'regionEn', width: 200 },
    { title: 'Sub Region', dataIndex: 'subRegionEn', width: 200 },
    { title: 'Eligible VIN No.', dataIndex: 'eligibleVinNo', width: 200 },
    {
        title: 'Support Amount w/o VAT',
        dataIndex: 'supportAmountVatStr',
        width: 200,
    },
    // { title: "Operation", dataIndex: "checkResult", width: 200, slots: { customRender: "operation" } },
]);
interface TreeDataItem {
    value: string;
    key: string;
    title?: string;
    disabled?: boolean;
    children?: TreeDataItem[];
}
const qmOptions: TreeDataItem[] = [
    {
        title: 'Q1',
        value: 'Q1',
        key: 'Q1',
        children: [
            {
                title: '01',
                value: '01',
                key: '01',
            },
            {
                title: '02',
                value: '02',
                key: '02',
            },
            {
                title: '03',
                value: '03',
                key: '03',
            },
        ],
    },
    {
        title: 'Q2',
        value: 'Q2',
        key: 'Q2',
        children: [
            {
                title: '04',
                value: '04',
                key: '04',
            },
            {
                title: '05',
                value: '05',
                key: '05',
            },
            {
                title: '06',
                value: '06',
                key: '06',
            },
        ],
    },
    {
        title: 'Q3',
        value: 'Q3',
        key: 'Q3',
        children: [
            {
                title: '07',
                value: '07',
                key: '07',
            },
            {
                title: '08',
                value: '08',
                key: '08',
            },
            {
                title: '09',
                value: '09',
                key: '09',
            },
        ],
    },
    {
        title: 'Q4',
        value: 'Q4',
        key: 'Q4',
        children: [
            {
                title: '10',
                value: '10',
                key: '10',
            },
            {
                title: '11',
                value: '11',
                key: '11',
            },
            {
                title: '12',
                value: '12',
                key: '12',
            },
        ],
    },
];

export default defineComponent({
    components: {
        DM,
    },
    setup() {
        const spaceSize = 6;
        const { state } = useStore();
        const searchCon = ref();
        const tableHeight = ref();
        //显示的表格数据
        const dealerListData = ref<any[]>([]);
        const parameterList = ref<any>({
            bu: null,
            programPeriodYear: '',
            programPeriodQM: null,
            dealerCode: '',
            dealerSwtCode: '',
            dealerName: '',
            dealerGroup: '',
        });
        //period初始化
        const programPeriodYear = ref<string>(moment().format('YYYY'));
        const openProgramPeriodYear = ref<boolean>(false);
        const programPeriodDis = ref<boolean>(false);
        const programPeriodQM = ref<string[]>([`Q${moment().quarter()}`]);
        const handlerOpenProgramPeriodChange = (status: any): void => {
            openProgramPeriodYear.value = status;
        };
        const handlerPanelProgramPeriodChange = (value: string): void => {
            const time = moment(value).format('YYYY');
            programPeriodYear.value = time as string;
            openProgramPeriodYear.value = false;
            if (time) {
                programPeriodDis.value = false;
            } else {
                programPeriodDis.value = true;
                programPeriodQM.value = [];
            }
        };
        const programPeriodChange = (value: string) => {
            if (value) {
                programPeriodDis.value = false;
            } else {
                programPeriodDis.value = true;
                programPeriodQM.value = [];
            }
        };
        //获取bu选项,region选项
        const businessUnitSelect = ref<any>([]);
        const buList = state.user.buList;
        const buNameenList = ref<any>([]);
        const doGetBuList = () => {
            businessUnitSelect.value = state.user.buList.filter((bu: any) => {
                return bu.parentId === null;
            });
            const bulst = ref<any>([]);
            const hasmb = ref<any>(false);
            buList.forEach((i: any, ind: any) => {
                let isrepeat = false;
                bulst.value.forEach((bui: any, buind: any) => {
                    if (i.nameEn === bui) {
                        isrepeat = true;
                    }
                });
                if (!isrepeat) {
                    bulst.value.push(i.nameEn);
                }
                if (i.nameEn === 'MBPC') {
                    hasmb.value = true;
                }
            });
            buNameenList.value = bulst.value;
            if (hasmb.value) {
                parameterList.value.bu = 'MBPC';
            } else {
                parameterList.value.bu = businessUnitSelect.value[0].nameEn;
            }
        };
        doGetBuList();

        //重置
        const handlerReset = () => {
            parameterList.value.bu = '';
            parameterList.value.programPeriodYear = '';
            parameterList.value.programPeriodQM = null;
            parameterList.value.dealerCode = '';
            parameterList.value.dealerSwtCode = '';
            parameterList.value.dealerName = '';
            parameterList.value.dealerGroup = '';
            programPeriodYear.value = moment().format('YYYY');
            programPeriodQM.value = [`Q${moment().quarter()}`];
            doGetBuList();
        };

        const genPeriod = () => {
            const programPeriod = {
                programPeriodYear: '',
                programPeriodMonth: [''],
            };
            const monthToQuarter = {
                '01': 1,
                '02': 1,
                '03': 1,
                '04': 2,
                '05': 2,
                '06': 2,
                '07': 3,
                '08': 3,
                '09': 3,
                '10': 4,
                '11': 4,
                '12': 4,
            };
            const quarterToMonth = {
                Q1: ['1-01', '1-02', '1-03'],
                Q2: ['2-04', '2-05', '2-06'],
                Q3: ['3-07', '3-08', '3-09'],
                Q4: ['4-10', '4-11', '4-12'],
            };
            const year = programPeriodYear.value;
            const qmList = programPeriodQM.value;
            const monthList = qmList.reduce((mthList: any, qm: string) => {
                if (qm in quarterToMonth) {
                    return [...mthList, ...quarterToMonth[qm]];
                } else {
                    return [...mthList, `${monthToQuarter[qm]}-${qm}`];
                }
            }, []);
            programPeriod.programPeriodYear = year;
            programPeriod.programPeriodMonth = monthList.map((mthList: any) => {
                return `${year}-${mthList}`;
            });
            return programPeriod;
        };

        //整理获取parameter
        const parameterDataLst = ref<any>({
            bu: '',
            dealerCode: '',
            dealerCodeSwt: '',
            dealerName: '',
            dealerGroup: '',
            programPeriodYear: '',
            programPeriodMonth: [''],
        });
        const dogetParameter = () => {
            parameterDataLst.value.dealerCode = parameterList.value.dealerCode;
            parameterDataLst.value.dealerCodeSwt =
                parameterList.value.dealerSwtCode;
            parameterDataLst.value.dealerName = parameterList.value.dealerName;
            parameterDataLst.value.dealerGroup =
                parameterList.value.dealerGroup;
            parameterDataLst.value.bu = [parameterList.value.bu];
            const { programPeriodYear, programPeriodMonth } = genPeriod();
            parameterDataLst.value.programPeriodYear = programPeriodYear;
            parameterDataLst.value.programPeriodMonth = programPeriodMonth;
        };
        nextTick(() => {
            (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height =
                window.innerHeight - 215 - searchCon.value.scrollHeight + 'px';
            const height = (document.getElementsByClassName(
                'table-box'
            )[0] as any).style.height;
            const heightNum = parseInt(height.substr(0, height.indexOf('px')));
            tableHeight.value = heightNum - 190;
        });
        // 获取dealergroup
        const dealerGroupList = ref([]);
        const genDealerGroup = () => {
            getDealerGroup().then((res: any) => {
                dealerGroupList.value = res;
            });
        };
        genDealerGroup();
        const salesCompetitionList = ref([]);
        const doGetSalesCompetitionList = () => {
            dogetParameter();
            getSalesCompetitionList(parameterDataLst.value).then((res: any) => {
                salesCompetitionList.value = res;
            });
        };
        const dogetAllData = () => {
            doGetSalesCompetitionList();
        };
        dogetAllData();
        const handlerSearch = () => {
            dogetParameter();
        };

        const downloadTableData = [
            {
                file: 'Raw Data',
                sign: 1,
            },
            {
                file: 'Report Data',
                sign: 2,
            },
        ];
        const downloadTypeVisible = ref(false);
        const download = () => {
            // if (!payoutRoundYear.value) {
            //     message.error('Payout Round (Year) and BU must be selected!');
            //     return;
            // }
            downloadTypeVisible.value = true;
        };
        const closeDownloadTypeModal = () => {
            downloadTypeVisible.value = false;
        };
        const handleOk = (selectedData: any) => {
            dogetParameter();
            let downloadType;
            if (selectedData.length > 1) {
                downloadType = 3;
            } else {
                downloadType = selectedData[0].sign;
            }
            parameterDataLst.value.downType = downloadType;
            parameterDataLst.value.language = 'EN';
            const paramsConfig = {
                url: `/claimapi/salesCompetitionResult/download`,
                method: 'post',
                params: parameterDataLst.value,
            };
            downloadFile(
                paramsConfig,
                'application/vnd-excel; char-set=UTF-8'
            ).then((_: any) => {
                downloadTypeVisible.value = false;
            });
        };
        const handlerDownloadCheckingFile = (type: any) => {
            dogetParameter();
            parameterDataLst.value.downType = 4;
            const paramsConfig = {
                url: `/claimapi/salesCompetitionResult/downloadCheckingFile`,
                method: 'post',
                params: parameterDataLst.value,
            };
            downloadFile(paramsConfig, 'application/vnd-excel; char-set=UTF-8');
        };

        return {
            spaceSize,
            handlerDownloadCheckingFile,
            dealerGroupList,
            genDealerGroup,
            doGetSalesCompetitionList,
            salesCompetitionList,
            downloadTableData,
            download,
            closeDownloadTypeModal,
            downloadTypeVisible,
            handleOk,
            dogetAllData,
            buNameenList,
            handlerSearch,
            dogetParameter,
            programPeriodChange,
            programPeriodDis,
            buList,
            handlerReset,
            doGetBuList,
            businessUnitSelect,
            handlerPanelProgramPeriodChange,
            handlerOpenProgramPeriodChange,
            openProgramPeriodYear,
            programPeriodYear,
            programPeriodQM,
            qmOptions,
            parameterList,
            searchCon,
            tableWidth,
            tableHeight,
            summaryCol,
            dealerListData,
            SHOW_PARENT,
        };
    },
});
